export default {
    namespaced: true,
    state: {
      estado: true,
      stateEng: false,
    },
    mutations: {
      setEstado(state) {
        state.estado = true;
        state.stateEng = false;
      },
      setStateEng(state) {
        state.stateEng = true;
        state.estado = false;
      },
    },
    actions: {
      cambiar({ commit }) {
        commit('setEstado');
      },
      change({ commit }) {
        commit('setStateEng');
      },
    },
  }